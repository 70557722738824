import React, { useState, useEffect } from "react";
import Landing from "./Landing";
import SEO from "./seo";
import "../styles/global.css";
import * as styles from "../styles/stories.module.css";
import Showcase from "../components/Showcase";
import Header2 from "../components/Header2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const Layout = ({
  children,
  data,
  profileData,
  setProfile,
  language,
  isDefaultLanguage,
  messages,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    window.onscroll = function () {
      const height = document?.getElementById("landingContainer")?.clientHeight;
      if (window.pageYOffset > height) {
        setShowFooter(true);
      } else {
        setShowFooter(false);
      }
    };
  });

  const setModal = (value) => {
    setShowModal(value);
  };

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setModal(false);
    setOpen(!open);
  };

  let toPublish = [];
  let comingSoon = [];
  for (let i = 0; i < data?.allAirtable.edges.length; i++) {
    if (
      data.allAirtable.edges[i].node.data.Status === "Published" &&
      data.allAirtable.edges[i].node.data.Language === language
    ) {
      toPublish.push(data.allAirtable.edges[i].node);
    } else if (
      data.allAirtable.edges[i].node.data.Status === "ToBePublished" &&
      data.allAirtable.edges[i].node.data.Language === language
    ) {
      comingSoon.push(data.allAirtable.edges[i].node);
    }
  }
  toPublish = toPublish.sort(function (a, b) {
    return b.data.Display_Order - a.data.Display_Order;
  });

  return (
    <div className={styles.storiesWrapper}>
      <SEO
        title="Everyone is Good at Something"
        description="For those who have habitually attended the India Inclusion Summit (IIS) in Bengaluru, its tagline 'Everyone is Good at Something' is like an old familiar melody. Equally familiar is the recurring image of the Inclusion Tree on which birds of different colours perch and co-exist."
        image="/logotree.png?v=1.0.0"
        image_resize="/logotree.png?v=1.0.0"

      />
      <Landing
        language={language}
        isDefaultLanguage={isDefaultLanguage}
        messages={messages}
      />

      <div id="allstories" className={styles.allStoriesWrapper}>
        <Header2
          setModal={setModal}
          showModal={showModal}
          open={open}
          handleClick={handleClick}
          language={language}
        />
        <div className={styles.cardsWrapper}>
          <Showcase
            data={toPublish}
            profileData={profileData}
            comingSoonData={comingSoon}
            setProfile={setProfile}
            showModal={showModal}
            setModal={setModal}
            open={open}
            handleClick={handleClick}
            language={language}
            messages={messages}
          />
          {showFooter && (
            <a
              href="https://legislative.gov.in/sites/default/files/A2016-49_1.pdf"
              target="_blank"
            >
              <div className="footerContainer">
                {messages.disabilityAct}
                <FontAwesomeIcon
                  className={styles.externalLinkIcon}
                  icon={faExternalLinkAlt}
                  size={"1x"}
                />
              </div>
            </a>
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
