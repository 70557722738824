import React, { useState, useEffect } from "react";
import Layout from "../components/Layout";
import { graphql, navigate } from "gatsby";
import { useCookies } from "react-cookie";

export const query = graphql`
  query MyQuery {
    allAirtable(filter: { table: { eq: "Data" } }) {
      edges {
        node {
          table
          data {
            Name
            Alt_Photos
            Alt_Text_Photo1
            Alt_Text_Photo2
            Alt_Text_Photo3
            Alt_Text_Photo4
            Alt_Text_Photo5
            Alt_Text_Video
            Photo1_URL
            Photo2_URL
            Photo3_URL
            Photo4_URL
            Photo5_URL
            Name_English
            Language
            CDN_Photo_URL {
              url
            }
            Changed_By {
              email
              id
              name
            }
            Changed_On
            Created_By {
              email
              id
              name
            }
            Created_On
            Disability
            Gender
            ID
            Instagram_Content
            Language
            Photos {
              filename
              url
            }
            Quote
            Status
            Video
            Website_Content
            State_name
            Display_Order
          }
          recordId
        }
      }
    }
  }
`;

const Home = ({ pageContext, data }) => {
  const [cookies, setCookie] = useCookies([]);
  const [profileData, setProfileData] = useState([]);
  useEffect(() => {
    console.log(!cookies.language);
    if (!cookies.language){
      navigate('/selector')
    }
    else if (cookies.language != pageContext.language) {
      if (cookies.language == pageContext.defaultLanguage) {
        navigate(`/`);
      } else {
        navigate(`/${cookies.language}`);
      }
    }
  }, [cookies, pageContext.language, pageContext.defaultLanguage]);

  const setProfile = (userData) => {
    setProfileData(userData);
  };

  return (
    <>
      <Layout
        data={data}
        profileData={profileData}
        setProfile={setProfile}
        language={pageContext.language}
        isDefaultLanguage={pageContext.isDefaultLanguage}
        messages={pageContext.messages}
      />
    </>
  );
};

export default Home;
