import React from "react";
import Carousel from "react-elastic-carousel";
import * as styles from "../styles/landing.module.css";
import { Link } from "gatsby";

const Landing = ({ language, isDefaultLanguage, messages }) => {
  let aboutUrl = `/about`;
  if (!isDefaultLanguage) {
    aboutUrl = `/${language}/about`;
  }
  return (
    <section className={styles.landingcontainer} id="landingContainer">
     
      <div className={styles.h2Div}>
        <h2 className={styles.landingh2}>
          <span>{messages.everyone}</span>{" "}
          <span>
            <div className={styles.dot}></div> {messages.is}{" "}
            <div className={styles.dot}></div>
          </span>{" "}
          <span>{messages.good} </span>
          <span>
            <div className={styles.dot}></div> {messages.at}{" "}
            <div className={styles.dot}></div>
          </span>{" "}
          <span>{messages.something}</span>
        </h2>
      </div>
      <div className={styles.landingImgWrapper}>
        <Carousel>
        <img
            className={styles.landingImg}
            src="https://egsweb.s3.ap-south-1.amazonaws.com/site/about_6.png"
            alt="Illustration of protagonists of the stories"
          />
        <img
            className={styles.landingImg}
            src="https://egsweb.s3.ap-south-1.amazonaws.com/site/about_5.png"
            alt="Illustration of protagonists of the stories"
          />
        <img
            className={styles.landingImg}
            src="https://egsweb.s3.ap-south-1.amazonaws.com/site/about_4.png"
            alt="Illustration of protagonists of the stories"
          />
          <img
            className={styles.landingImg}
            src="https://egsweb.s3.ap-south-1.amazonaws.com/site/about_3.png"
            alt="Illustration of protagonists of the stories"
          />
          <img
            className={styles.landingImg}
            src="https://egsweb.s3.ap-south-1.amazonaws.com/site/about_2.png"
            alt="Illustration of protagonists of the stories"
          />
          <img
            className={styles.landingImg}
            src="https://egsweb.s3.ap-south-1.amazonaws.com/site/about_1.png"
            alt="Illustration of protagonists of the stories"
          />
        </Carousel>
      </div>
      <div className={styles.h2Div}>
        <h2 className={styles.landingMission}>
          <span>21 {messages.disabilities}</span>{" "}
          <div className={styles.dot}></div>{" "}
          <span>28 {messages.states}</span>{" "}
          <div className={styles.dot}></div>{" "}
          <span>{messages.stories}</span>{" "}
        </h2>
      </div>
      <div className={styles.aboutWrapper}>
        <p className={styles.aboutText}>{messages.homeDescription}</p>
      </div>
      <div className={styles.continueReading}>
        <Link to={aboutUrl}>
          <p className={styles.addHover}>{messages.continueReading}</p>
        </Link>
      </div>

      <div
        className={styles.viewStoriesWrapper}
        onClick={() =>
          document.getElementById("allstories").scrollIntoView({
            behavior: "smooth",
          })
        }
      >
        <span className={styles.scrollDown}>
          <span className={styles.mouse}>
            <span></span>
          </span>
        </span>
        <h2 className={styles.addHover}>{messages.viewStories}</h2>
      </div>
    </section>
  );
};

export default Landing;
