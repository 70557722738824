// extracted by mini-css-extract-plugin
export var aboutContentText = "landing-module--aboutContentText--Hnj96";
export var aboutContentWrapper = "landing-module--aboutContentWrapper--EUfBt";
export var aboutImg = "landing-module--aboutImg--2w5sH";
export var aboutImgWrapper = "landing-module--aboutImgWrapper--agAQM";
export var aboutText = "landing-module--aboutText--YN7BD";
export var aboutViewStoriesWrapper = "landing-module--aboutViewStoriesWrapper--u5myt";
export var aboutWrapper = "landing-module--aboutWrapper--G1SzU";
export var aboutcontainer = "landing-module--aboutcontainer--USEJH";
export var addHover = "landing-module--addHover--cWEgA";
export var continueReading = "landing-module--continueReading--HY0B3";
export var dot = "landing-module--dot--RBYsk";
export var flex = "landing-module--flex--RJ187";
export var h2Div = "landing-module--h2Div--ra4rP";
export var landingImg = "landing-module--landingImg--5zJo4";
export var landingImgWrapper = "landing-module--landingImgWrapper--0prDx";
export var landingMission = "landing-module--landingMission--4LmMM";
export var landingUl = "landing-module--landingUl--gEjNE";
export var landingcontainer = "landing-module--landingcontainer--AWhvR";
export var landingh2 = "landing-module--landingh2--ZWDm0";
export var languageContainer = "landing-module--languageContainer--b+rcd";
export var mouse = "landing-module--mouse--nhy0H";
export var moveWheel = "landing-module--move-wheel--IP694";
export var scrollDown = "landing-module--scroll-down--6xaF4";
export var viewStoriesWrapper = "landing-module--viewStoriesWrapper--9EjWW";